<template>
  <div
    v-if="(showFitType || showFabric) && productIntroData"
    class="product-intro__fitAndFabric"
  >
    <div
      v-if="showFitType"
      class="product-intro__fitAndFabric-title"
      :style="{ fontSize: isModal ? '18px' : '14px' }"
    >
      {{ language.SHEIN_KEY_PC_17747 }}
    </div>
    <div
      v-if="showFitType"
      class="product-intro__fitAndFabric-dec"
    >
      <div
        v-for="(item, index) in fitType"
        :key="index"
      >
        <div
          class="product-intro__fitAndFabric-pos"
          :class="{
            'product-intro__fitAndFabric-posR': fitType.length - 1 === index,
            'product-intro__fitAndFabric-posC':
              fitType.length - 1 !== index && index !== 0,
            'product-intro__fitAndFabric-posL': index === 0,
            'product-intro__fitAndFabric-select': showFitType.key === item.key,
          }"
        >
          <div
            v-show="showFitType.key === item.key"
            class="product-intro__fitAndFabric-icon"
          ></div>
          <div
            v-if="item.value"
            class="product-intro__fitAndFabric-text"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showFabric"
      class="product-intro__fitAndFabric-title"
      :style="{ fontSize: isModal ? '18px' : '14px' }"
    >
      {{ language.SHEIN_KEY_PC_18014 }}
    </div>
    <div
      v-if="showFabric"
      class="product-intro__fitAndFabric-dec"
    >
      <div
        v-for="(item, index) in fabric"
        :key="index"
      >
        <div
          class="product-intro__fitAndFabric-pos"
          :class="{
            'product-intro__fitAndFabric-posR': fabric.length - 1 === index,
            'product-intro__fitAndFabric-posC':
              fabric.length - 1 !== index && index !== 0,
            'product-intro__fitAndFabric-posL': index === 0,
            'product-intro__fitAndFabric-select': showFabric.key === item.key,
          }"
        >
          <div
            v-show="showFabric.key === item.key"
            class="product-intro__fitAndFabric-icon"
          ></div>
          <div
            v-if="item.value"
            class="product-intro__fitAndFabric-text"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isModal: Boolean,
    productIntroData: {
      default: () => {},
      type: Object,
    },
    language: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    fitType() {
      return [
        {
          key: 'Skinny',
          value: this.language.SHEIN_KEY_PC_17719,
        },
        {
          key: 'Slim Fit',
          value: '',
        },
        {
          key: 'Regular Fit',
          value: this.language.SHEIN_KEY_PC_17720,
        },
        {
          key: 'Loose',
          value: '',
        },
        {
          key: 'Oversized',
          value: this.language.SHEIN_KEY_PC_17721,
        },
      ]
    },
    fabric() {
      return [
        {
          key: 'Non-Stretch',
          value: this.language.SHEIN_KEY_PC_18015,
        },
        {
          key: 'Slight Stretch',
          value: this.language.SHEIN_KEY_PC_18016,
        },
        {
          key: 'Medium Stretch',
          value: this.language.SHEIN_KEY_PC_18017,
        },
        {
          key: 'High Stretch',
          value: this.language.SHEIN_KEY_PC_18018,
        },
      ]
    },
    showFitType() {
      if (!this.productIntroData?.detail) return
      const multiPartProductDetails = this.productIntroData.detail
        .isMultiPartProduct
        ? (this.productIntroData.detail.productDetails || []).concat(
          this.productIntroData.detail.multiPartInfo.reduce(function (
            prev,
            item
          ) {
            prev.push(...(item.attributeList || []))
            return prev
          },
          [])
        )
        : this.productIntroData.detail.productDetails || []
      const find = multiPartProductDetails.find(
        (i) => i.attr_name === 'Fit Type'
      )
      const findIt = find && find['attr_value_en']
      return this.fitType.find((i) => i.key === findIt)
    },
    showFabric() {
      if (!this.productIntroData?.detail) return
      const multiPartProductDetails = this.productIntroData.detail
        .isMultiPartProduct
        ? (this.productIntroData.detail.productDetails || []).concat(
          this.productIntroData.detail.multiPartInfo.reduce(function (
            prev,
            item
          ) {
            prev.push(...(item.attributeList || []))
            return prev
          },
          [])
        )
        : this.productIntroData.detail.productDetails || []
      const findFabric = multiPartProductDetails.find(i => i.attr_name_en === 'Fabric')
      const findFabricIt = findFabric && findFabric['attr_value_en']
      if(findFabricIt) {
        return this.fabric.find(i => i.key === findFabricIt)
      }
      const findStretch = multiPartProductDetails.find(i => i.attr_name_en === 'Stretch')
      const findStretchIt = findStretch && findStretch['attr_value_en']
      if(findStretchIt) {
        return this.fabric.find(i => i.key === findStretchIt)
      }
      return false
    },
  },
}
</script>

<style lang="less">
.product-intro {
  &__size-modal .c-modal .modal-dialog {
    &.modal-full {
      max-width: 1000px;
      top: 50%;
      transform: translate(-50%, -50%) /*rtl:ignore*/;
      margin: 0;
    }
    .common-sizemeasure__detail {
      min-width: 400px;
      flex-direction: row;
    }
    .modal-content {
      padding: 32px 48px;
      .padding-r(38px);
    }
    .modal-body {
      max-height: 420px;
      width: 620px;
      overflow-y: auto;
      .padding-r(10px);
      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
  &__fitAndFabric {
    margin-bottom: 31px;
    font-size: 12px;
  }
  &__fitAndFabric-title {
    font-weight: 700;
    color: #222;
  }
  &__fitAndFabric-dec {
    display: flex;
    border-top: 1px solid #222;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 22px;
    padding-bottom: 15px;
    &.product-intro__fitAndFabric-text {
      border-top: none;
      margin: 0;
      padding: 0;
      margin-bottom: 24px;
    }
    div {
      position: relative;
    }
    span {
      position: absolute;
      top: 15px;
      white-space: nowrap;
    }
  }
  &__fitAndFabric-pos {
    position: absolute;
    display: block;
    background: #222;
    width: 1px;
    height: 6px;
    top: 0px;
    .product-intro__fitAndFabric-text {
      top: 17px;
      position: absolute;
      white-space: nowrap;
    }
    &.product-intro__fitAndFabric-select {
      background: none;
    }
  }
  &__fitAndFabric-icon {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 7px 0 7px;
    border-color: #222 transparent transparent transparent;
  }
  &__fitAndFabric-posR {
    right: 0;
    .product-intro__fitAndFabric-icon {
      .left(-14px);
    }
    .product-intro__fitAndFabric-text {
      .right(0);
      text-align: right;
    }
  }
  &__fitAndFabric-posC {
    transform: translateX(-50%);
    left: 50%;
    .product-intro__fitAndFabric-icon {
      .left(-7px);
    }
    .product-intro__fitAndFabric-text {
      transform: translateX(-50%);
      left: 50%;
      text-align: center;
    }
  }
}
</style>
